<template>
 <div 
  class="modal fade modal-mask"
    id="GBVModal"
    tabindex="1"
    role="dialog"
    aria-labelledby="fwEditModal"
    aria-hidden="true"
  >
  <div class="modal-wrapper">
     <div class="modal-container" :style='getAppStyle()+getContentStyle()'>
         <div class='appHead'>
            
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'>
             </div>
             
              <button id='closePopup2' type="button" class="iButton" @click='maximize()' aria-label="Maximize">
              <span aria-hidden="true">
              <svg v-show='width != 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
				  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
			  </svg>
              <svg v-show='width == 100' xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
				  <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
			   </svg>
              </span>
              
              </button>
              <WindowCloseBN :withDarkMode="false" @click="close"/>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft'>
            	
            	{{gridAds.detail.name}} AdSpace-Structure
            	
                </div>
                <div class='appRight'>
                
             
                </div>
            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div class='mainContent' >
           
             <div class='transition' :style='getControlStyle()'>
	    		<!-- CONTROLS -->
			    <div :style="getAppStyle()+'float: top; width: 100%;  vertical-align: middle;'" class='smaller'>
			        
			        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'><a @click="openEntity($event, 'GridDetail', gridAds.detail.id, (gridAds.originalHeader.id !== gridAds.header.id))">{{gridAds.detail.name}}</a></div>
			        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'><a @click="openEntity($event, 'Content', gridAds.content.id)">{{gridAds.content.name}}</a></div>

			  		
			        Starttime: {{printTime( gridAds.starttime,":")}} <a @click="openEntity($event, 'Timezone', timezoneStation.id)">{{timezoneStation.shortname}}</a><br/>
			        Weekdays: {{printWeekdays(gridAds.detail.weekdays)}}<br/>
			 
			        <div>
			        Duration: {{printTime(gridAds.detail.durationISOInSeconds,":", true)}}
			        </div>
			        
			        <div v-if="gridAds.category">
			        Category: <span class='gBold'><a @click="openEntity($event, 'ContentCategory', gridAds.category.id)">{{gridAds.category.name}}</a></span><br/>
			        </div>
			        <div v-if="gridAds.genre">
					Genre: <span class='gBold'><a @click="openEntity($event, 'Genre', gridAds.genre.id)">{{gridAds.genre.name}}</a></span>
					<span v-if="gridAds.subGenre" class='gBold'><a @click="openEntity($event, 'Genre', subGenre.category.id)">, {{gridAds.subGenre.name}}</a></span>
					<br/>
					</div>
					
					<div v-if="gridAds.parentalRating">
					ParentalRating: <span class='gBold'><a @click="openEntity($event, 'ParentalRating', gridAds.parentalRating.id)">{{gridAds.parentalRating.name}}</a></span><br/>
					</div>
					<div v-if="gridAds.targetGroup">
					Targetgroup: <span class='gBold'><a @click="openEntity($event, 'TargetGroup', gridAds.targetGroup.id)">{{gridAds.targetGroup.name}}</a></span><br/>
					</div>
					
					<div v-if="gridAds.zone" :style="'background-color: '+gridAds.zone.colour+'; color: '+autoFG(gridAds.zone.colour)">
			  		Zone: {{gridAds.zone.name}}
			  		</div>
			  		<vSelect :disabled='!editable()' placeholder='choose zone' :options="zoneOptions" style='width:100%' 
			  		         v-model="selectedZone" @input='setZone'>
			          <template :style='getRCOptionStyle(colour)' #option="{ id, label, colour }">
      					<div :style='getRCOptionStyle(colour)'>{{label}}</div>
    				  </template>
			        </vSelect>
			  		
					
					<div v-if="gridAds.rateCard">
					Pricegroup: <span class='gBold'><a @click="openEntity($event, 'RateCard', gridAds.rateCard.id)">{{gridAds.rateCard.name}} ({{gridAds.detail.pricegroup}})</a></span> 
					&nbsp; <a @click="openEntity($event, 'PriceIndex', gridAds.priceIndex.id)">{{gridAds.priceIndex.name}}</a><br/>
					<span @click='showRC=!showRC'>Price: {{Number(gridAds.rateCard.price).toLocaleString(myLocale())}} {{currency.shortname}} / {{gridAds.rateCard.duration}}"</span><br/>
					</div>
					<vSelect :disabled='!editable()' placeholder='choose pricegroup' :options="rateCardsOption" style='width:100%' v-model="selectedRC" @input='setPriceGroup'>
			          <template :style='getRCOptionStyle(colour)' #option="{ id, label, price, colour }">
      					<div :style='getRCOptionStyle(colour)'>{{label}} {{Number(price).toLocaleString(myLocale())}} {{currency.shortname}}</div>
    				  </template>
			        </vSelect>
					<span class="small" v-show="!showRC">
					<ul>
					<li> {{calculatePrice(25)}} {{currency.shortname}} / 25"</li>
					<li> {{calculatePrice(20)}} {{currency.shortname}} / 20"</li>
					<li> {{calculatePrice(15)}} {{currency.shortname}} / 15"</li>
					<li> {{calculatePrice(10)}} {{currency.shortname}} / 10"</li>
					</ul>
					</span>
					<div v-show="gridAds.originalHeader.id !== gridAds.header.id">
			        	Header: <a @click="openEntity($event, 'GridHeader', gridAds.header.id)">{{gridAds.header.name}}</a> from <a @click="openEntity($event, 'GridHeader', gridAds.originalHeader.id)">{{gridAds.originalHeader.name}}</a><br/>
			        	<button type="button" @click='newVersion(gridAds.detail.id, gridAds.header.id)' class='button myButton'>Create version for {{gridAds.header.name}}</button>
			        </div>
			        
			        <div v-show="gridAds.originalHeader.id === gridAds.header.id && gridAds.detail.derivedFromId">
			            <button type="button" @click='deleteVersion(gridAds.detail.id, gridAds.header.id)' class='button myButton'>Delete version for {{gridAds.header.name}}</button>
			        </div>
					<br/>
					<div v-if="gridAds.firstRun">
					Firstrun: <a @click='switchToView(gridAds.firstRun.id)'>{{gridAds.firstRun.name}}</a>
					    &nbsp;<span class="small">{{printTime( gridAds.firstRun.starttime,":")}}</span> <span class="small">{{printWeekdays(gridAds.firstRun.weekdays)}}</span>
					</div>
					<div v-if="gridAds.reRuns && gridAds.reRuns.length > 0">
					Reruns: <ul>
					<div v-for="(rerun,idx) in gridAds.reRuns" :key="'rerun_' + idx">
						<li><a @click='switchToView(rerun.id)'>{{rerun.name}}</a> 
						&nbsp;<span class="small">{{printTime( rerun.starttime,":")}}</span> <span class="small">{{printWeekdays(rerun.weekdays)}}</span></li>
					</div>
					</ul>
			    	</div>
			    	<!-- 
					<div v-if="gridAds.detail.breakDetails && gridAds.detail.breakDetails.details && gridAds.detail.breakDetails.details.length">
					 <div>Break-structure: {{gridAds.detail.breakDetails.details[0].dayOfWeek}}</div>
					 <div v-for="(bd, bdi) in gridAds.detail.breakDetails.details.filter(n=>n.weekDayBit==dayOfWeekBit)" :key="'bd'+bdi">
					 	<div>{{bd.number}} / {{bd.pricegroup}} / {{ printTimeHHMM( bd.time)}} 
					 	     // {{printTimeMMSS( bd.duration)}} // {{bd.label}}</div>
					 </div>
					
					</div>
			    	 -->
				
				<!--
		    	<div v-for="(rcIdx,idx) in rateCards" :key="'ratec_'+idx" class='transitionFast' :style='getPGStyle(rcIdx)'>
		    	    <a style='color: black;' @click="setPriceGroup($event, rcIdx)">{{ rcIdx.name }}:&nbsp;&nbsp;{{Number(rcIdx.price).toLocaleString(myLocale())}} {{currency.shortname}}</a>
		    	</div>
		    	-->
		    	 <br/>
		    	    <div v-if="daySpecific(dayOfWeekBit)">
		    	    
		    	    <table width=100%>
		    	    <tr>
		    	    <td >
		    	    <b>{{weekday[Math.round(Math.log(dayOfWeekBit)/Math.log(2))]}}</b> (day specific) 
		    	    </td>
		    	    <td>
		    	    <button type="button" :disabled='!editable()' 
			                @click='removeBreakDetails( dayOfWeekBit)' class='button myButton'>remove</button>
		    	    </td>
		    	    </tr>
		    	    </table>
		    	    </div>
		    	    <div v-else>
		    	    <table width=100%>
		    	    <tr>
		    	    <td >
		    	    <span style='color: #888;'>{{weekday[Math.round(Math.log(dayOfWeekBit)/Math.log(2))]}}</span> (day specific) 
		    	    </td>
		    	    <td>
		    	    <button type="button" :disabled='!editable()' 
			                @click='setNoOfInterruptions( dayOfWeekBit, getNoOfInterruptions(dayOfWeekBit))' class='button myButton'>create</button>
		    	    </td>
		    	    </tr>
		    	    </table>
		    	    </div>
		    	    <table width=100%>
		    	    <tr>
		    	    <td >
		    	    <div class='SVcontrolLabel' >Breaks/Interruptions: {{getNoOfInterruptions(dayOfWeekBit)}}</div>
		    	    </td>
		    	    <td>
			        <button :disabled="getNoOfInterruptions(dayOfWeekBit)<=0 || !editable()" type="button" 
			                @click='setNoOfInterruptions( dayOfWeekBit, getNoOfInterruptions(dayOfWeekBit)-1)' class='button myButton'>-</button>
			        <button type="button" :disabled='!editable()' 
			                @click='setNoOfInterruptions( dayOfWeekBit, getNoOfInterruptions(dayOfWeekBit)+1)' class='button myButton'>+</button>
			        </td>
			        </tr>
			        </table>
			        <br/>
			        GENERAL (Default)
			       
			        <table width=100%>
		    	    <tr>
		    	    <td >
		    	    <div class='SVcontrolLabel' >Breaks/Interruptions: {{noOfInterruptions}}</div>
		    	    </td>
		    	    <td>
			        <button :disabled="noOfInterruptions<=0 || !editable()" type="button" @click='noOfInterruptions--' class='button myButton'>-</button>
			        <button type="button" :disabled='!editable()' @click='noOfInterruptions++' class='button myButton'>+</button>
			        </td>
			        </tr>
			        </table>
			        <!--
			        <br/>
		    	    <span class='SVcontrolLabel'>Zoom: ({{zoom}})</span>
			        <vue-slider  :min='10' :max='50'  v-model="zoom"></vue-slider>
			        -->
			        
			        <br/>
			    	<span class='SVcontrolLabel'>Show Controls: </span>
			    	&nbsp;
			    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
				
			    </div> <!-- CONTROLS -->
    		</div>
    
    		<!-- MAIN -->
            <div class='transition'  style='float: left; width: 65%;'> 
			    <!-- HEADER -->
			    <div style='float: top; background-color: #eef; width: 100%; height: 28pt; overflow-y: scroll;'>
				    <div style='float: top;'>
					    <div style='float: left; width: 10%;'>
					       <div class='header'>
					       		{{station.shortname}}{{timezoneStation.shortname}}
					       	</div>
					    </div>
					   
					    <div v-if="hasTimezone()" style='float: left; width: 10%;'>
					       <div class='header'>
					       		{{timezoneLocal.shortname}}
					       	</div>
					    </div>
					    <div style='float: left; width: 80%;'>
					       <div class='header'>
					       		{{gridAds.detail.name}}
					       	</div>
					    </div>
				    </div>  <!-- float: top -->  
				</div> <!-- HEADER -->
				<div style='background-color: #eef;width: 100%; height: calc(100vH - 334px); overflow-y: scroll;'>
			    	<div style='float: top; width: 100%;height: 100%; width: 100%;background-color: #eef;'>
			    		<div style='float: left; width: 10%; color: #444; background-color: #eee;'>
			    			<!-- print time channel TZ -->
			       			<div class='time' :style='mainTime(time)' v-for="(time,idx) in grid" :key="'time_'+idx">
			        			<b>{{printTime( time,":")}}</b>
			      			</div>
			    		</div> 
			   
			    		<div v-if="hasTimezone()" class='transition' style='float: left; width: 10%;'>
			    		    <!-- print time 2nd TZ -->
			    		    
			       			<div class='time' :style='gridHeight()' v-for="(time,idx) in grid" :key="'time2_'+idx">
			        			<b>{{printTime2( time+diff)}}</b>
			       			</div>
		
			    		</div>
				    	<!-- ALL WEEKDAYS -->
				    	<div style='float: left; width: 80%;background-color: #eef;' class="transition" >
				        	<div style='position: relative; width: 100% !important;background-color: #eef; color: #000;'>
				        		<span style='color: transparent;'>anchor</span>
						    	<div :title='gridAds.detail.name' class='gridMainBV' :style='gridStyle(gridAds)'
						             >
				           			<div  style='line-height: 1; position: relative;'>
							           	<span style='font-size: 8pt; text-align: center; display: block;padding-top: 4ex;'><b>{{ gridAds.detail.name }}</b></span>
							           	<span style='font-size: 8pt; text-align: center; display: block;'>{{printTime( gridAds.detail.starttime,":")}}</span>
							           	<div v-if="gridAds.rateCard">
							           	<span style='font-size: 8pt; text-align: center; display: block;'>PG-{{gridAds.detail.pricegroup}} ({{Number(gridAds.rateCard.price).toLocaleString(myLocale())}}{{currency.shortname}})</span>
							           	</div>
							           	<div v-for="(adspace,idx1) in adSpaces(gridAds)" :style='adStyle(gridAds, adspace)' class='adMainBV' :key="'adspace_'+idx1" :title='adLabel(adspace)' >
				           					<table style='width: 100%;'>
				           					
				           					<tr v-for="(brk, idx2) in adspace.breakGrids"  :key="'AS'+idx1+'.'+idx2" :title='adLabel(adspace)'>
				           					   <td v-if="idx2==0" :rowspan="adspace.breakGrids.length" style='vertical-align: middle;' class='adMainInnerLeft'>
				           					   	<b>{{adLabel(adspace)}}</b>
				           					   
				           					   </td>
				           					   <td class='adMainInnerRight'>
				           					   <ul>
				           					   <li>
				           					    <span class="breakDetail" style="width: 80%"
				           					          >
				           					     <div class="breakDetail" @click="openBreakDetailEditor( gridAds, adspace, brk, adspace.no)">
				           					     {{brk.name}} ({{printTimeMMSS(brk.durationInSeconds,":")}})
				           					   	 </div>
				           					   	 <button title='remove this break' style='top: 0pt; background-color: transparent; border: 1px solid #ccc; float: right;' @click="removeBD( brk, gridAds)">
				           					   	 <mdicon  size='12' name='delete'></mdicon>
				           					   	 </button>
				           					   	</span>
				           					   	
				           					   	</li></ul>
				           					   </td>
				           					</tr> 
				           					</table>
				           				</div> 
				           			</div>
			           			</div>
			           		</div>
		        		</div><!-- ALL WEEKDAYS -->
			    	</div> 
		    	</div>  <!-- TABLE -->
            </div> <!-- float: left -->
			</div> <!-- mainContent -->
	      </slot>
	       <BreakDetailEditor ref='breakDetailEdit' @remove=removeBD @changed=updateBD>
	     </BreakDetailEditor>
	    </div> <!-- modal-body -->
	    
	  </div> <!-- modal-container -->  	  
	 </div> <!-- modal-wrapper -->  	
	
	 <GFWEditorRouter v-if='showEditor' @update="update" @insert="update" @close="closeEditor" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadata" 
    :dataId="dataId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter>  
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, setReload, myLocale,showError} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2, getBGColor, autoFG } from '@/AppStyle.js';
import {fmtTimePart, printTimeOpt, printTime2, getHour, printTimeHHMM, printTimeMMSS } from '@/basicTimeFN.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import BreakDetailEditor from '@/components/BreakDetailEditor';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
var timers = [];
export default {
  name: 'GFW Grid Break View',
  components : {
    vSelect, BreakDetailEditor,WindowCloseBN,
    'app-switch': Switch
  },
  props: {
    gridAdsInput: Object,
    gridDetailId: Number,
    replacement: Object,
    timezoneLocal: Object,
    dayOfWeekBit: Number,
    timezoneStation: Object,
    currency: Object,
    zones: Array,
    rateCards: Array,
    allRateCards: Array,
    station: Object,
    diff: {type: Number, defaultValue: 60},
    display: Boolean
  },
  data () {
    return {
    	weekday: ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"],
        gridAds: {},
		width: 70,
        height: 95,
        mainHeight: 75,
        unit: 15,
        zoom: 15,
        zoneOptions: [],
        zoomArr: [10,12,14,15,16,18,20,22],
        grid: [],
        weekdayLabel: [],
        withAds: true,
        starttime: 0,
        dayStartAt: 0,
        controlsRight: true,
        mapping: [],
        metadata: {},
        entityMap: [],
        dataId: 0,
        selectedRC: {},
        selectedZone: {},
        rateCardsOption: [],
        showRC: false,
        selectedRecord: {},
        selectedIndex: 0,
        allEntities: [],
        noOfInterruptions: -1,
        inProgress: false,
        showEditor: false,
        getAppStyle,
        printTimeHHMM,
        printTimeMMSS,
        autoFG
    }
  },
  methods: {
     getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
     myLocale() { return myLocale();},
      getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 33%; height: calc(100% - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 33%; height: calc(100% - 200px); padding: 1ex;"
    },
     editable() {
        return !this.inProgress && this.gridAds.originalHeader.id === this.gridAds.header.id;
     },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
     decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
     close() { this.$emit("close"); },
     gridHeight() { return "height: "+this.zoom+"pt;";},
     hasTimezone() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     hasGenre() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     hasSubgenre() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     calculatePrice(dur)
     {
    	if ( this.gridAds.rateCard )
    	{
	     	let base = this.gridAds.rateCard.price/this.gridAds.rateCard.duration;
	     	var returnValue = 0;
	     	for (var pi in this.gridAds.priceIndexes )
	     	{
	     		let index = this.gridAds.priceIndexes[pi];
	     		if ( index.durFrom <= dur && index.durTo >= dur )
	     		{
	     			returnValue = index.index/100*base*(dur*index.factor+index.value);
	     			break;
	     		}
	     	} 
	     	if ( this.gridAds.priceIndex.rounding )
	     	{
	     		return Number(Math.round(returnValue)).toLocaleString(myLocale())
	     	}
	       return Number(Math.round(returnValue*100)/100).toLocaleString(myLocale())
    	}
     },
    
     openBreakDetailEditor( gridAds, adspace, brk, no)
     {
    	 this.$refs.breakDetailEdit.open( brk, gridAds, this.currency, this.rateCardsOption, brk.name);
     },
     updateBD( breakGrid, gridAds)
     {
    	 let that = this;
    	 this.startLoader()
    	 breakGrid.duration = 'PT'+breakGrid.durationInSeconds+'S';
    	 let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
    	 HTTP.put( invAPI+"/updateBreakDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.timezoneStation.id+"/"+timezoneId+"/"+that.dayOfWeekBit, breakGrid )
          .then( response => 
          { 
              that.gridAds = response.data;
              that.update();
              that.stopLoader();
              
          }).catch(e => {
               
					showError(  that.$toast, "update grid", e);
					that.stopLoader();
          });
     },
     removeBD( breakGrid, gridAds)
     {
    	 let that = this;
    	 breakGrid.duration = 'PT'+breakGrid.durationInSeconds+'S';
    	 this.startLoader()
    	 let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
    	 HTTP.put( invAPI+"/removeBreakDetail/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.timezoneStation.id+"/"+timezoneId+"/"+that.dayOfWeekBit, breakGrid )
          .then( response => 
          { 
              that.gridAds = response.data;
              that.update();
              that.stopLoader();
          }).catch(e => {
               
					showError(  that.$toast, "update grid", e);
					that.stopLoader();
          });
     },
     getBreakDetail( gridAds, brk, no)
     {
    	 let list = this.getBreakDetails( gridAds);
    	 if (list && list.details)
    	 {
    		 if ( brk )
    		 {
    		 	return list.details.find(n=>n.breakTypeId==brk.id && n.number==no); 
    	 	 }
    		 return list.details.find(n=>n.number==no); 
    	}
		return null;
     },
     getRCOptionStyle(colour) {
     	return "color: "+autoFG(colour)+" !important; font-weight: bold; font-size: 9pt; margin: 0pt !important; height: 100% !important; width: 100; padding: 0pt; background-color: "+colour+";";
     },
     newVersion( detailId, headerId) {
        let that = this;
        let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
       
     	let loader = new Promise(function (resolve, reject) {
     	    that.startLoader();
	        HTTP.get( invAPI+"/createNewDetailVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+detailId+"/"+headerId+"/"+that.timezoneStation.id+"/"+timezoneId)
	            .then( response => 
	            { 
	                that.gridAds = response.data;
	                that.update();
	                
	            }).catch(e => {
	                    that.$toast.error("new version: " + e.response.data, 'Error', { position: "topRight" });
     
	                    reject ();
	            });
	      });
     	
     },
     removeBreakDetails( dayOfWeekBit) 
     {
    	 let that = this;
    	 this.startLoader()
         let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
	    
	     let replaceId = ( that.replacement && that.replacement.id ) ? that.replacement.id : 0;
	        //alert( replaceId + JSON.stringify( that.replacement))
	       
	     let loader = new Promise(function (resolve, reject) {
		        HTTP.put( invAPI+"/removeBreakDetails/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+replaceId+"/"+that.timezoneStation.id+"/"+timezoneId+"/"+dayOfWeekBit, that.gridAds.detail)
    	            .then( response => 
	            { 
	                that.gridAds = response.data;
	                that.update();
	                
	            }).catch(e => {
	                 
     					showError(  that.$toast, "removeBreakDetails", e);
     					that.stopLoader();
	                    reject ();
	            });
	      });
     },
     setNoOfInterruptions( dayOfWeekBit, noOfInterruptions)
     {
     	let that = this;
     	
     	//alert(dayOfWeekBit + "/" + JSON.stringify(noOfInterruptions)+ "&"+that.gridAds.detail.noOfInterruptions)
        if ( (that.gridAds.detail.noOfInterruptions != noOfInterruptions) || dayOfWeekBit > -1 )
        {
            this.startLoader()
            let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
	        this.gridAds.detail.noOfInterruptions = noOfInterruptions;
	        let replaceId = ( that.replacement && that.replacement.id ) ? that.replacement.id : 0;
	        //alert( replaceId + JSON.stringify( that.replacement))
	       
	     	let loader = new Promise(function (resolve, reject) {
		        HTTP.put( invAPI+"/updateDetailVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.gridAds.detail.id+"/"+replaceId+"/"+that.gridAds.header.id+"/"+that.timezoneStation.id+"/"+timezoneId+"/99/false/"+dayOfWeekBit+"/true", that.gridAds.detail)
		            .then( response => 
		            { 
		                that.gridAds = response.data;
		                that.update();
		                
		            }).catch(e => {
		                 
	     					showError(  that.$toast, "update grid", e);
	     					that.stopLoader();
		                    reject ();
		            });
		      });
	        
		}
     },
     setPriceGroup( ratecard )
     {
     	let that = this;
     	if ( this.gridAds.detail.pricegroup != ratecard.pg )
     	{
     	    this.startLoader()

	     	//alert(JSON.stringify(ratecard))
	        let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
	        this.gridAds.detail.pricegroup = ratecard.pg;
	        let replaceId = ( that.replacement && that.replacement.id ) ? that.replacement.id : 0;
	        
	     	let loader = new Promise(function (resolve, reject) {
		        HTTP.put( invAPI+"/updateDetailVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.gridAds.detail.id+"/"+replaceId+"/"+that.gridAds.header.id+"/"+that.timezoneStation.id+"/"+timezoneId+"/99/false/"+that.dayOfWeekBit+"/true", that.gridAds.detail)
		            .then( response => 
		            { 
		                that.gridAds = response.data;
		                that.update();
		                
		            }).catch(e => {
		                    showError(  that.$toast, "update grid", e);
	     					that.stopLoader();
		                    reject ();
		            });
		      });
		 }
     },
     setZone( zone )
     {
     	let that = this;
     	if ( this.gridAds.detail.zoneId != zone.id )
     	{
     	    this.startLoader()

	     	//alert(JSON.stringify(ratecard))
	        let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
	        this.gridAds.detail.zoneId = zone.id;
	        let replaceId = ( that.replacement && that.replacement.id ) ? that.replacement.id : 0;
	        
	     	let loader = new Promise(function (resolve, reject) {
		        HTTP.put( invAPI+"/updateDetailVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.gridAds.detail.id+"/"+replaceId+"/"+that.gridAds.header.id+"/"+that.timezoneStation.id+"/"+timezoneId+"/99/false/"+that.dayOfWeekBit+"/true", that.gridAds.detail)
		            .then( response => 
		            { 
		                that.gridAds = response.data;
		                that.update();
		                
		            }).catch(e => {
		                    showError(  that.$toast, "update grid", e);
	     					that.stopLoader();
		                    reject ();
		            });
		      });
		 }
     },
     getPGStyle(rc)
     {
    	if ( this.showRC )
    	{
    		return "background-color: "+rc.colour+"; font-size: 7pt; width: 40%; text-align: center; margin-left: 6ex;";
    	}
    	else
    	{
    		return "background-color: "+rc.colour+"; font-size: 7pt; width: 40%; text-align: center; margin-left: 6ex;";
    		//return "background-color: "+rc.colour+"; font-size: 0pt; width: 40%; text-align: center;"
    	}
     },
     deleteVersion( detailId, headerId) {
        let that = this;
        this.startLoader()
        let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
        
     	let loader = new Promise(function (resolve, reject) {
	        HTTP.get( invAPI+"/deleteNewDetailVersion/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+detailId+"/"+headerId+"/"+that.timezoneStation.id+"/"+timezoneId)
	            .then( response => 
	            { 
	                that.gridAds = response.data;
	                //that.switchToView ( that.gridAds.detail.id );
	                that.update();
	                
	            }).catch(e => {
	                    that.$toast.error("delete version: " + e.response.data, 'Error', { position: "topRight" });
     
	                    reject ();
	            });
	      });
     	
     },
     openEntity( evt, entity, id, readonly )
     {
        let that = this;
        //console.log(fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/"+id)
        let loader = new Promise(function (resolve, reject) {
	        HTTP.get( fwAPI+"/entityByIdWithMetadata/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+entity+"/"+id)
	            .then( response => 
	            { 
	                //alert(JSON.stringify(response.data));
	                that.selectedRecord = response.data.data;
	                that.metadata = response.data.metadata;
	                if ( readonly )
	                {
	                	that.metadata.editable = false;
	                	for ( var ci in that.metadata.columns )
	                	{
	                		that.metadata.columns[ci].editable = false;
	                	}
	                }
	                
	                that.metadata = response.data.metadata;
	                
	                resolve (response.data);
	                
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      });
	      
	   loader.then( inventory => {
        that.mapping = [ {row: 0, dataId: that.selectedRecord.id, rId: 0, visible: true}];
    	that.allEntities = [ that.selectedRecord];
		that.dataId = that.selectedRecord.id;
		that.entityMap=[];
    	that.showEditor = true;
    	});
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
     },
     closeEditor()
    {
    	 this.$set( this, "showEditor", false)
    	 
         this.showEditor = false;
    	 this.$forceUpdate();
    },
    update() {
       this.switchToView ( this.gridAds.detail.id );
       this.$emit('update', this.gridAds.detail );
       
    },
     switchToView(id) {
       let that = this;
       let timezoneId = this.hasTimezone()?this.timezoneLocal.id:0;
       let inventoryLoad = new Promise(function (resolve, reject) {
       let replId = that.replacement.id ? that.replacement.id : 0;         
                 
    	   
   	    console.log(invAPI+"/findSingle/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id+"/"+that.replacement.id+"/"+that.gridAds.header.id+"/"+that.timezoneStation.id+"/"+timezoneId);
        HTTP.get( invAPI+"/findSingle/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+id+"/"+replId+"/"+that.gridAds.header.id+"/"+that.timezoneStation.id+"/"+timezoneId+"/"+that.dayOfWeekBit)
       		 .then( response => 
                {  
                   that.gridAds = response.data;
                   //alert( JSON.stringify(response.data));
                   resolve(response.data)    
                }).catch(e => {
               	    showError(  that.$toast, "switchToView", e.response);
                       //that.$toast.error("switchToView: " + e.response.data, 'Error', { position: "topRight" });
                       reject ();
                   });

          });
	    inventoryLoad.then(data => {
	   	   this.setValues();
	   });
     },
     timezoneStyle() {
        if ( this.hasTimezone() )
        {
    		return "float: left; width: 10%  !important; background-color: #eee; color: #444;";
    	}
    	return "float: left; width: 0pt !important; background-color: #eee; color: transparent;";
    },
     weekDayIndexes() {
            var intArr = [];
            for ( var i=0; i < this.weekdayLabel.length; i++ )
            {
                intArr.push((i + parseInt(sessionStorage.weekStart)) % 7);
            }
            return intArr;
        },
     printWeekdays(wd) {
          let p2 = 1;
          var x = [];
          for ( var ii = 0; ii < 7; ii++ )
          {
            if ((wd & p2) == p2)
            {
            	x[ii] = true;
            }
            else
            {
            x[ii] = false;
            }
            p2 = p2 * 2;
          }
          var retString = "";
          //alert( x +" / "+ this.weekDayIndexes() +" // "+ this.weekdayLabel);
          let arr = this.weekDayIndexes();
          let cnt = 0;
          for ( ii in arr )
          {
            let idx = arr[ii]
          	retString += ( x[idx] ) ? this.weekdayLabel[idx]: "-";
          	if ( cnt < 6 )
          	{
          		retString += ",";
          	}
          	cnt++; 
          }
          //alert( x +" / "+ this.weekDayIndexes() +" / "+ this.weekdayLabel +" --> " + retString);
          return retString;
     },
     setValues()
      {
          try
          {
	      	  //alert( JSON.stringify(this.gridAds));
	          //console.log("set>" +this.val + " " + this.value);
			  this.startLoader();
	          this.noOfInterruptions = this.gridAds.detail.noOfInterruptions;
			  if ( this.gridAds && this.gridAds.detail )
			  {
		          this.starttime = this.gridAds.starttime;
		          this.weekdayLabel = [];
		          let wdArr = JSON.parse(sessionStorage.weekdays);
		          for ( var i=1; i < 8; i++ )
		          {
		             let x = wdArr[i];
		             if ( x.length > 2 )
		             {
		                 x = x.substring(0,2);
		             }
		             this.weekdayLabel.push( x);    
		          }
		          //
		          //alert( JSON.stringify(this.gridAds))
		          let duration = this.gridAds.detail.durationISOInSeconds;
		         
		          this.unit = duration/25/60; // in Minutes
		          //alert( this.gridAds.detail.duration +"-->"+this.unit+"/"+(this.gridAds.detail.duration*this.unit));
		          if ( this.unit > 5 )
		          {
		          	this.unit = this.unit + 5 - this.unit%5;
		          }
		          //alert( this.gridAds.detail.duration +"-->"+this.unit+"/"+(this.gridAds.detail.duration*this.unit));
		          //alert( this.gridAds.detail.duration +"-->"+this.unit);
		          //alert( this.gridAds.detail.duration + this.unit);
		          this.grid=[];
		          this.dayStartAt = this.starttime-5*60;
		          //alert( this.printTime(this.starttime));
		          for ( var m=this.dayStartAt; m <= this.starttime + duration*60 + 5*60;)
		          {
		              let unit = this.unit;
		          	  this.grid.push(m);
			          m=m+unit*60;
		          }
		          this.zoneOptions = this.zones.map(p=>{ return {id: p.id, label: p.name, colour: p.colour}})
		          this.rateCardsOption = this.allRateCards.map(r => {return {id: r.id, label: r.name, colour: r.colour, price: r.price, pg: r.pricegroup}});
		          this.selectedZone = this.zoneOptions.find( z=>z.id === this.gridAds.detail.zoneId)
		          
		          this.selectedRC = this.rateCardsOption.find( r=> r.pg===this.gridAds.detail.pricegroup);
	
		      }
		  } catch (e) {
		   //
		  }
	      this.stopLoader();
      },
      fmtTimePart( aTime) {
      	if ( aTime < 10 ) 
      	{
      		return "0"+aTime;
      	}
      	return ""+aTime;
      },
      mainTime(time) { 
        let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        if ( hh >= 24 )
        {
        	return "height: "+this.zoom+"pt; background-color: #e0e0ff;";
        }
      	return "height: "+this.zoom+"pt;";
      },
      printTime(time,sep,withSec)
      {
        //console.log( "scanTime("+val+") " + this.time )
        //alert("scanTime("+JSON.stringify(val)+") " + this.time)
        if ( isNaN( time ) || ! time  )
        {
        	time = 0;
        }
      	let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        let hhVal = this.fmtTimePart(hh);
        let mmVal = this.fmtTimePart(mm);
        if ( withSec )
        {
        	let ssVal = this.fmtTimePart(ss);
        	return hhVal + sep + mmVal + sep + ssVal;
        }
        return hhVal + sep + mmVal;
       },
      getBreakCode(adSpace, brk)
      {
    	let breakD = this.getBreakDetail( this.gridAds, brk, adSpace.no);
    	let label = ( breakD ) ? breakD.label:null;
    	if ( label )
    	{
    		return label;
    	}
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "OP";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "END";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
        code += "."+brk.shortname;
        code += "@";
      	if ( this.gridAds.category )
      	{
      		code += this.gridAds.category.shortname;
      	}
      	code += this.printTime(this.gridAds.starttime,"");
      	return code;
      },
      printTime2(time)
      {
        //console.log( "scanTime("+val+") " + this.time )
        //alert("scanTime("+JSON.stringify(val)+") " + this.time)
        if ( isNaN( time ) || ! time  )
        {
        	time = 0;
        }
        let minusDay=false;
        let plusDay=false;
        if ( time < 0 )
        {
        	time += 24*3600;
        	minusDay = true;
        }
      	let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        
        
        if ( hh >= 24 )
        {
        	hh -= 24;
        	plusDay = true;
        }
        
        let hhVal = this.fmtTimePart(hh);
        let mmVal = this.fmtTimePart(mm);
        //let ssVal = this.fmtTimePart(ss);
        return hhVal +":"+mmVal +((plusDay)?" +1":"")+((minusDay)?" -1":"");
       },
    gridStyle(grid) {
    	var style = "top: ";
    	var height = grid.detail.durationISOInSeconds/60 * this.zoom / this.unit; 
    	var start = (grid.detail.starttime-(this.dayStartAt))/60* this.zoom / this.unit
    	style += start+"pt; height: "+height+"pt;";
    	//alert( start + "/"+this.dayStartAt );
    	if ( grid.rateCard )
    	{
    		style += "background-color: "+grid.rateCard.colour+";";
    	}
    	return style;
    },
    adSpaces( grid)
    {
    	if ( !this.withAds  )
    	{
    		//return [];
    	}
    	let wd = Math.round(Math.log(this.dayOfWeekBit)/Math.log(2));
    	if ( grid.adspacePerWeekday && grid.adspacePerWeekday[wd])
    	{

    		return grid.adspacePerWeekday[wd];
    		
    	}
    	return grid.adspace;
    },
    adLabel(ad) {
        var text = "";
        if ( ad.section == 0 )
        {
        	text = "OPENER";
        }
        else if ( ad.section == 2 )
        {
        	text =  "CLOSER";
        }
        else
        {
        	text =  "CENTER-"+ad.no;
        }
        return text;
    },
    getBreakDetails( gridAds)
    {
   	 if (gridAds.detail.breakDetails)
   	 {
   		 return gridAds.detail.breakDetails.details.find(n=>n.weekDayBit==this.dayOfWeekBit); 
   	 }
		return null;
    },
    getNoOfInterruptions( dayOfWeekBit )
    {
    	
    	let detailsDay = this.adSpaces( this.gridAds);
		if ( detailsDay)
		{
			let breakNos = detailsDay.filter(p => p.no > 0 && p.no < 999).map(p=>p.no);
			console.log( JSON.stringify( breakNos))
			if ( !breakNos.length)
			{
				return 0;
			}
			return breakNos.length; // Math.max(...breakNos);
			
		}
    	return this.gridAds.detail.noOfInterruptions;
    },
    daySpecific( dayOfWeekBit )
    {
    	if (this.gridAds.detail.breakDetails && this.gridAds.detail.breakDetails.details)
    	{
    		
    		let detailsDay = this.gridAds.detail.breakDetails.details.find(n=>n.weekDayBit==dayOfWeekBit);
    		
    		if ( detailsDay && detailsDay.details)
    		{
    			return true;
    		}
    	}
    	return false;
    },
    adStyle(grd, ad) {
            	
    	var height = 6 * this.zoom / this.unit; 
    	if ( !this.withAds  )
    	{
    		return "background-color: transparent; color: transparent;";
    	}
    	if ( height > 16 )
    	{
    		height = 14;
    		
    	}
    	var fontSize = 1+height/2;
    	if ( fontSize < 7 )
    	{
    		fontSize = 7;
    	}
    	
    	let start = ad.estStarttime;
    	let bd = this.getBreakDetail( this.gridAds, null, ad.no);
    	if ( bd )
    	{
    		start = bd.time;
    	}
    	//alert( ad.estStarttime+"/"+start +"/" +grd.detail.starttime+ "/" +this.zoom+"/"+this.unit )
    	start = ((start - grd.detail.starttime))/60* this.zoom / this.unit;
    	if ( ad.section == 0 )
        {
        	start = -height
        } 

    	var style =  "top: " + start+"pt; font-size: "+fontSize+"pt;";
    	style += "border-top: .5pt solid #aaa;";
    	//console.log( grd.detail.name +"-"+ad.no+"/"+ad.estStarttime+"/"+grd.detail.starttime+"/"+this.dayStartAt+" -> "+style);
    	return style;
    },
    startLoader()
    {
    	if ( !this.inProgress )
      	{
	      	this.inProgress = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                   
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.inProgress = false;
		this.loader.hide();
	}
  },
  watch: {
	//replacement: function () { alert(this.replacement?this.replacement.id:0)},
  	gridAdsInput : function()  { this.gridAds = this.gridAdsInput; this.setValues() },
  	gridDetailId: function()  { this.gridAds = this.gridAdsInput; this.setValues() },
  	timezoneLocal : function()  { this.grid=[]; this.setValues() },
  	noOfInterruptions: function()  { this.setNoOfInterruptions( -1, this.noOfInterruptions); }
  },
  created() {
	this.setValues();
	initAppMode();
  }
}
</script>
<style>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
 .appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding-left: 0pt;
  padding-bottom: 20pt;
  border-radius: 2px;
  display: table;
  border: 2pt inset #aaa;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.gridMainBV { position: absolute; z-Index: 2; text-align: center; width: 99% ; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
.adMainBV { 
  float: top;
  position: absolute; 
  align-items: center;
  justify-content: center;
  z-Index: 17; 

  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 
  border: .5pt solid black;
    opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s;
}
.adMainInnerRight { 
  color: #000; 
  width: 60%;
  text-align: left !important;
  //border-bottom: .5pt solid #ddd;
  padding-left: 5pt;
  background-color: rgba(255, 255, 255, .6);
  //border-left: .5pt solid #ddd;
    opacity: 1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.breakDetail {
 display: inline-block; 
 padding: 1pt; 
 font-size: 9pt; 
 color: #444;
}
.breakDetail:hover {
 display: inline-block; 
 padding: 1pt; 
 font-size: 8pt; 
 color: #fff;
 cursor: pointer;
 background-color: #888;
}
.adMainInnerLeft { 
  align-items: center;
  justify-content: center;
  float: left top;
  z-Index: 17; 
  padding-top: 2pt;
  font-size: 9pt;
  background-color: rgba(255, 255, 255, .6);
  font-weight: bold;
  margin-left: 2pt; 
  margin-right: 4pt; 
  color: #666; 
  height: 100% !important;
  
    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }

.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}

</style>
